/** @format */

import React from 'react';

const LogoBlack = () => (
	<svg
		width='38'
		height='48'
		viewBox='0 0 38 48'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.2041 41.8754C12.959 43.3553 16.0815 45.0318 18.6377 47.63L19.0011 48L19.3645 47.63C21.9292 45.019 25.0559 43.3424 27.815 41.8647L27.834 41.854C31.2079 40.0449 34.1212 38.4838 35.9845 35.6482C37.3451 33.5803 38.0042 30.9842 38 27.7102V0H0.00012494V27.7188C-0.0104382 30.9864 0.648702 33.5803 2.01557 35.6525C3.88102 38.4817 6.80279 40.0492 10.1851 41.8669L10.2041 41.8776V41.8754ZM19.0011 22.7511L2.59021 34.6175C1.53178 32.7976 1.02053 30.5352 1.02898 27.7188V24.5496L19.0032 11.5541L36.9775 24.5496V27.7124C36.9817 30.533 36.4683 32.7976 35.4141 34.6153L19.0032 22.749L19.0011 22.7511ZM19.0011 10.2753L1.02686 23.2708V1.03929H36.9732V23.2729L18.999 10.2775L19.0011 10.2753ZM27.3354 40.9452C24.6376 42.3908 21.5975 44.0182 19.0011 46.5352C16.4131 44.031 13.3773 42.4015 10.6858 40.9559L10.6668 40.9452C7.58235 39.2879 4.8972 37.8444 3.15851 35.4814L19.0011 24.0257L34.8437 35.4814C33.1092 37.8466 30.4304 39.2858 27.3544 40.9345L27.3354 40.9452Z'
			fill='#1A1A1A'
		/>
		<path
			d='M21.0544 40.0234C21.0544 39.7369 20.8241 39.5038 20.541 39.5038C20.2579 39.5038 20.0277 39.7369 20.0277 40.0234H19.5143V39.5038C19.7974 39.5038 20.0277 39.2707 20.0277 38.9841C20.0277 38.6976 19.7974 38.4645 19.5143 38.4645C19.5143 38.1779 19.284 37.9448 19.0009 37.9448C18.7178 37.9448 18.4876 38.1779 18.4876 38.4645C18.2045 38.4645 17.9742 38.6976 17.9742 38.9841C17.9742 39.2707 18.2045 39.5038 18.4876 39.5038V40.0234H17.9742C17.9742 39.7369 17.7439 39.5038 17.4608 39.5038C17.1777 39.5038 16.9475 39.7369 16.9475 40.0234C16.6644 40.0234 16.4341 40.2565 16.4341 40.5431C16.4341 40.8296 16.6644 41.0627 16.9475 41.0627C16.9475 41.3493 17.1777 41.5824 17.4608 41.5824C17.7439 41.5824 17.9742 41.3493 17.9742 41.0627H18.4876V42.5832C18.2045 42.5832 17.9742 42.8162 17.9742 43.1028C17.9742 43.3894 18.2045 43.6225 18.4876 43.6225C18.4876 43.6225 18.4897 43.6225 18.4918 43.6225C18.4918 43.6353 18.4876 43.6481 18.4876 43.6609C18.4876 43.9475 18.7178 44.1806 19.0009 44.1806C19.284 44.1806 19.5143 43.9475 19.5143 43.6609C19.5143 43.6481 19.5122 43.6353 19.5101 43.6225C19.5101 43.6225 19.5122 43.6225 19.5143 43.6225C19.7974 43.6225 20.0277 43.3894 20.0277 43.1028C20.0277 42.8162 19.7974 42.5832 19.5143 42.5832V41.0627H20.0277C20.0277 41.3493 20.2579 41.5824 20.541 41.5824C20.8241 41.5824 21.0544 41.3493 21.0544 41.0627C21.3375 41.0627 21.5678 40.8296 21.5678 40.5431C21.5678 40.2565 21.3375 40.0234 21.0544 40.0234Z'
			fill='#1A1A1A'
		/>
		<path
			d='M21.0544 28.5893C21.0544 28.3028 20.8241 28.0697 20.541 28.0697C20.2579 28.0697 20.0277 28.3028 20.0277 28.5893H19.5143V28.0697C19.7974 28.0697 20.0277 27.8366 20.0277 27.55C20.0277 27.2635 19.7974 27.0304 19.5143 27.0304C19.5143 26.7438 19.284 26.5107 19.0009 26.5107C18.7178 26.5107 18.4876 26.7438 18.4876 27.0304C18.2045 27.0304 17.9742 27.2635 17.9742 27.55C17.9742 27.8366 18.2045 28.0697 18.4876 28.0697V28.5893H17.9742C17.9742 28.3028 17.7439 28.0697 17.4608 28.0697C17.1777 28.0697 16.9475 28.3028 16.9475 28.5893C16.6644 28.5893 16.4341 28.8224 16.4341 29.109C16.4341 29.3955 16.6644 29.6286 16.9475 29.6286C16.9475 29.9152 17.1777 30.1483 17.4608 30.1483C17.7439 30.1483 17.9742 29.9152 17.9742 29.6286H18.4876V31.1491C18.2045 31.1491 17.9742 31.3822 17.9742 31.6687C17.9742 31.9553 18.2045 32.1884 18.4876 32.1884C18.4876 32.1884 18.4897 32.1884 18.4918 32.1884C18.4918 32.2012 18.4876 32.214 18.4876 32.2269C18.4876 32.5134 18.7178 32.7465 19.0009 32.7465C19.284 32.7465 19.5143 32.5134 19.5143 32.2269C19.5143 32.214 19.5122 32.2012 19.5101 32.1884C19.5101 32.1884 19.5122 32.1884 19.5143 32.1884C19.7974 32.1884 20.0277 31.9553 20.0277 31.6687C20.0277 31.3822 19.7974 31.1491 19.5143 31.1491V29.6286H20.0277C20.0277 29.9152 20.2579 30.1483 20.541 30.1483C20.8241 30.1483 21.0544 29.9152 21.0544 29.6286C21.3375 29.6286 21.5678 29.3955 21.5678 29.109C21.5678 28.8224 21.3375 28.5893 21.0544 28.5893Z'
			fill='#1A1A1A'
		/>
		<path
			d='M14.3786 33.7856C14.3786 33.4991 14.1483 33.266 13.8653 33.266C13.5822 33.266 13.3519 33.4991 13.3519 33.7856H12.8385V33.266C13.1216 33.266 13.3519 33.0329 13.3519 32.7463C13.3519 32.4598 13.1216 32.2267 12.8385 32.2267C12.8385 31.9401 12.6082 31.707 12.3251 31.707C12.0421 31.707 11.8118 31.9401 11.8118 32.2267C11.5287 32.2267 11.2984 32.4598 11.2984 32.7463C11.2984 33.0329 11.5287 33.266 11.8118 33.266V33.7856H11.2984C11.2984 33.4991 11.0681 33.266 10.785 33.266C10.5019 33.266 10.2717 33.4991 10.2717 33.7856C9.98858 33.7856 9.7583 34.0187 9.7583 34.3053C9.7583 34.5918 9.98858 34.8249 10.2717 34.8249C10.2717 35.1115 10.5019 35.3446 10.785 35.3446C11.0681 35.3446 11.2984 35.1115 11.2984 34.8249H11.8118V36.3454C11.5287 36.3454 11.2984 36.5785 11.2984 36.865C11.2984 37.1516 11.5287 37.3847 11.8118 37.3847C11.8118 37.3847 11.8139 37.3847 11.816 37.3847C11.816 37.3975 11.8118 37.4103 11.8118 37.4231C11.8118 37.7097 12.0421 37.9428 12.3251 37.9428C12.6082 37.9428 12.8385 37.7097 12.8385 37.4231C12.8385 37.4103 12.8364 37.3975 12.8343 37.3847C12.8343 37.3847 12.8364 37.3847 12.8385 37.3847C13.1216 37.3847 13.3519 37.1516 13.3519 36.865C13.3519 36.5785 13.1216 36.3454 12.8385 36.3454V34.8249H13.3519C13.3519 35.1115 13.5822 35.3446 13.8653 35.3446C14.1483 35.3446 14.3786 35.1115 14.3786 34.8249C14.6617 34.8249 14.892 34.5918 14.892 34.3053C14.892 34.0187 14.6617 33.7856 14.3786 33.7856Z'
			fill='#1A1A1A'
		/>
		<path
			d='M27.7304 34.8269C28.0135 34.8269 28.2438 34.5938 28.2438 34.3072C28.2438 34.0207 28.0135 33.7876 27.7304 33.7876C27.7304 33.501 27.5001 33.2679 27.2171 33.2679C26.934 33.2679 26.7037 33.501 26.7037 33.7876H26.1903V33.2679C26.4734 33.2679 26.7037 33.0348 26.7037 32.7483C26.7037 32.4617 26.4734 32.2286 26.1903 32.2286C26.1903 31.9421 25.96 31.709 25.6769 31.709C25.3939 31.709 25.1636 31.9421 25.1636 32.2286C24.8805 32.2286 24.6502 32.4617 24.6502 32.7483C24.6502 33.0348 24.8805 33.2679 25.1636 33.2679V33.7876H24.6502C24.6502 33.501 24.4199 33.2679 24.1368 33.2679C23.8538 33.2679 23.6235 33.501 23.6235 33.7876C23.3404 33.7876 23.1101 34.0207 23.1101 34.3072C23.1101 34.5938 23.3404 34.8269 23.6235 34.8269C23.6235 35.1134 23.8538 35.3465 24.1368 35.3465C24.4199 35.3465 24.6502 35.1134 24.6502 34.8269H25.1636V36.3473C24.8805 36.3473 24.6502 36.5804 24.6502 36.867C24.6502 37.1535 24.8805 37.3866 25.1636 37.3866C25.1636 37.3866 25.1657 37.3866 25.1678 37.3866C25.1678 37.3994 25.1636 37.4123 25.1636 37.4251C25.1636 37.7117 25.3939 37.9447 25.6769 37.9447C25.96 37.9447 26.1903 37.7117 26.1903 37.4251C26.1903 37.4123 26.1882 37.3994 26.1861 37.3866C26.1861 37.3866 26.1882 37.3866 26.1903 37.3866C26.4734 37.3866 26.7037 37.1535 26.7037 36.867C26.7037 36.5804 26.4734 36.3473 26.1903 36.3473V34.8269H26.7037C26.7037 35.1134 26.934 35.3465 27.2171 35.3465C27.5001 35.3465 27.7304 35.1134 27.7304 34.8269Z'
			fill='#1A1A1A'
		/>
		<path
			d='M21.0544 17.1528C21.0544 16.8663 20.8241 16.6332 20.541 16.6332C20.2579 16.6332 20.0277 16.8663 20.0277 17.1528H19.5143V16.6332C19.7974 16.6332 20.0277 16.4001 20.0277 16.1135C20.0277 15.827 19.7974 15.5939 19.5143 15.5939C19.5143 15.3073 19.284 15.0742 19.0009 15.0742C18.7178 15.0742 18.4876 15.3073 18.4876 15.5939C18.2045 15.5939 17.9742 15.827 17.9742 16.1135C17.9742 16.4001 18.2045 16.6332 18.4876 16.6332V17.1528H17.9742C17.9742 16.8663 17.7439 16.6332 17.4608 16.6332C17.1777 16.6332 16.9475 16.8663 16.9475 17.1528C16.6644 17.1528 16.4341 17.3859 16.4341 17.6725C16.4341 17.959 16.6644 18.1921 16.9475 18.1921C16.9475 18.4787 17.1777 18.7117 17.4608 18.7117C17.7439 18.7117 17.9742 18.4787 17.9742 18.1921H18.4876V19.7126C18.2045 19.7126 17.9742 19.9456 17.9742 20.2322C17.9742 20.5188 18.2045 20.7518 18.4876 20.7518C18.4876 20.7518 18.4897 20.7518 18.4918 20.7518C18.4918 20.7647 18.4876 20.7775 18.4876 20.7903C18.4876 21.0769 18.7178 21.31 19.0009 21.31C19.284 21.31 19.5143 21.0769 19.5143 20.7903C19.5143 20.7775 19.5122 20.7647 19.5101 20.7518C19.5101 20.7518 19.5122 20.7518 19.5143 20.7518C19.7974 20.7518 20.0277 20.5188 20.0277 20.2322C20.0277 19.9456 19.7974 19.7126 19.5143 19.7126V18.1921H20.0277C20.0277 18.4787 20.2579 18.7117 20.541 18.7117C20.8241 18.7117 21.0544 18.4787 21.0544 18.1921C21.3375 18.1921 21.5678 17.959 21.5678 17.6725C21.5678 17.3859 21.3375 17.1528 21.0544 17.1528Z'
			fill='#1A1A1A'
		/>
		<path
			d='M7.70278 5.19638C7.70278 4.90983 7.47251 4.67673 7.18941 4.67673C7.18941 4.39018 6.95914 4.15709 6.67604 4.15709C6.39295 4.15709 6.16268 4.39018 6.16268 4.67673H5.64931V4.15709C5.9324 4.15709 6.16268 3.92399 6.16268 3.63744C6.16268 3.35088 5.9324 3.11779 5.64931 3.11779C5.64931 2.83124 5.41903 2.59814 5.13594 2.59814C4.85285 2.59814 4.62257 2.83124 4.62257 3.11779C4.33948 3.11779 4.1092 3.35088 4.1092 3.63744C4.1092 3.92399 4.33948 4.15709 4.62257 4.15709V4.67673H4.1092C4.1092 4.39018 3.87892 4.15709 3.59583 4.15709C3.31274 4.15709 3.08246 4.39018 3.08246 4.67673C2.79937 4.67673 2.56909 4.90983 2.56909 5.19638C2.56909 5.48293 2.79937 5.71603 3.08246 5.71603C3.08246 6.00258 3.31274 6.23567 3.59583 6.23567C3.87892 6.23567 4.1092 6.00258 4.1092 5.71603H4.62257V7.23648C4.33948 7.23648 4.1092 7.46957 4.1092 7.75612C4.1092 8.04268 4.33948 8.27577 4.62257 8.27577C4.62257 8.27577 4.62468 8.27577 4.62679 8.27577C4.62679 8.2886 4.62257 8.30143 4.62257 8.31426C4.62257 8.60082 4.85285 8.83391 5.13594 8.83391C5.41903 8.83391 5.64931 8.60082 5.64931 8.31426C5.64931 8.30143 5.64719 8.2886 5.64508 8.27577C5.64508 8.27577 5.64719 8.27577 5.64931 8.27577C5.9324 8.27577 6.16268 8.04268 6.16268 7.75612C6.16268 7.46957 5.9324 7.23648 5.64931 7.23648V5.71603H6.16268C6.16268 6.00258 6.39295 6.23567 6.67604 6.23567C6.95914 6.23567 7.18941 6.00258 7.18941 5.71603C7.47251 5.71603 7.70278 5.48293 7.70278 5.19638Z'
			fill='#1A1A1A'
		/>
		<path
			d='M16.4323 4.67673C16.4323 4.39018 16.2021 4.15709 15.919 4.15709C15.6359 4.15709 15.4056 4.39018 15.4056 4.67673H14.8922V4.15709C15.1753 4.15709 15.4056 3.92399 15.4056 3.63744C15.4056 3.35088 15.1753 3.11779 14.8922 3.11779C14.8922 2.83124 14.662 2.59814 14.3789 2.59814C14.0958 2.59814 13.8655 2.83124 13.8655 3.11779C13.5824 3.11779 13.3521 3.35088 13.3521 3.63744C13.3521 3.92399 13.5824 4.15709 13.8655 4.15709V4.67673H13.3521C13.3521 4.39018 13.1218 4.15709 12.8387 4.15709C12.5557 4.15709 12.3254 4.39018 12.3254 4.67673C12.0423 4.67673 11.812 4.90983 11.812 5.19638C11.812 5.48293 12.0423 5.71603 12.3254 5.71603C12.3254 6.00258 12.5557 6.23567 12.8387 6.23567C13.1218 6.23567 13.3521 6.00258 13.3521 5.71603H13.8655V7.23648C13.5824 7.23648 13.3521 7.46957 13.3521 7.75612C13.3521 8.04268 13.5824 8.27577 13.8655 8.27577C13.8655 8.27577 13.8676 8.27577 13.8697 8.27577C13.8697 8.2886 13.8655 8.30143 13.8655 8.31426C13.8655 8.60082 14.0958 8.83391 14.3789 8.83391C14.662 8.83391 14.8922 8.60082 14.8922 8.31426C14.8922 8.30143 14.8901 8.2886 14.888 8.27577C14.888 8.27577 14.8901 8.27577 14.8922 8.27577C15.1753 8.27577 15.4056 8.04268 15.4056 7.75612C15.4056 7.46957 15.1753 7.23648 14.8922 7.23648V5.71603H15.4056C15.4056 6.00258 15.6359 6.23567 15.919 6.23567C16.2021 6.23567 16.4323 6.00258 16.4323 5.71603C16.7154 5.71603 16.9457 5.48293 16.9457 5.19638C16.9457 4.90983 16.7154 4.67673 16.4323 4.67673Z'
			fill='#1A1A1A'
		/>
		<path
			d='M11.8095 9.87546C11.8095 9.58891 11.5793 9.35582 11.2962 9.35582C11.0131 9.35582 10.7828 9.58891 10.7828 9.87546H10.2694V9.35582C10.5525 9.35582 10.7828 9.12272 10.7828 8.83617C10.7828 8.54961 10.5525 8.31652 10.2694 8.31652C10.2694 8.02997 10.0391 7.79688 9.75605 7.79688C9.47296 7.79688 9.24268 8.02997 9.24268 8.31652C8.95959 8.31652 8.72931 8.54961 8.72931 8.83617C8.72931 9.12272 8.95959 9.35582 9.24268 9.35582V9.87546H8.72931C8.72931 9.58891 8.49904 9.35582 8.21595 9.35582C7.93285 9.35582 7.70258 9.58891 7.70258 9.87546C7.41948 9.87546 7.18921 10.1086 7.18921 10.3951C7.18921 10.6817 7.41948 10.9148 7.70258 10.9148C7.70258 11.2013 7.93285 11.4344 8.21595 11.4344C8.49904 11.4344 8.72931 11.2013 8.72931 10.9148H9.24268V12.4352C8.95959 12.4352 8.72931 12.6683 8.72931 12.9549C8.72931 13.2414 8.95959 13.4745 9.24268 13.4745C9.24268 13.4745 9.2448 13.4745 9.24691 13.4745C9.24691 13.4873 9.24268 13.5002 9.24268 13.513C9.24268 13.7995 9.47296 14.0326 9.75605 14.0326C10.0391 14.0326 10.2694 13.7995 10.2694 13.513C10.2694 13.5002 10.2673 13.4873 10.2652 13.4745C10.2652 13.4745 10.2673 13.4745 10.2694 13.4745C10.5525 13.4745 10.7828 13.2414 10.7828 12.9549C10.7828 12.6683 10.5525 12.4352 10.2694 12.4352V10.9148H10.7828C10.7828 11.2013 11.0131 11.4344 11.2962 11.4344C11.5793 11.4344 11.8095 11.2013 11.8095 10.9148C12.0926 10.9148 12.3229 10.6817 12.3229 10.3951C12.3229 10.1086 12.0926 9.87546 11.8095 9.87546Z'
			fill='#1A1A1A'
		/>
		<path
			d='M26.1884 5.19638C26.1884 4.90983 25.9581 4.67673 25.675 4.67673C25.675 4.39018 25.4447 4.15709 25.1616 4.15709C24.8785 4.15709 24.6483 4.39018 24.6483 4.67673H24.1349V4.15709C24.418 4.15709 24.6483 3.92399 24.6483 3.63744C24.6483 3.35088 24.418 3.11779 24.1349 3.11779C24.1349 2.83124 23.9046 2.59814 23.6215 2.59814C23.3384 2.59814 23.1082 2.83124 23.1082 3.11779C22.8251 3.11779 22.5948 3.35088 22.5948 3.63744C22.5948 3.92399 22.8251 4.15709 23.1082 4.15709V4.67673H22.5948C22.5948 4.39018 22.3645 4.15709 22.0814 4.15709C21.7983 4.15709 21.5681 4.39018 21.5681 4.67673C21.285 4.67673 21.0547 4.90983 21.0547 5.19638C21.0547 5.48293 21.285 5.71603 21.5681 5.71603C21.5681 6.00258 21.7983 6.23567 22.0814 6.23567C22.3645 6.23567 22.5948 6.00258 22.5948 5.71603H23.1082V7.23648C22.8251 7.23648 22.5948 7.46957 22.5948 7.75612C22.5948 8.04268 22.8251 8.27577 23.1082 8.27577C23.1082 8.27577 23.1103 8.27577 23.1124 8.27577C23.1124 8.2886 23.1082 8.30143 23.1082 8.31426C23.1082 8.60082 23.3384 8.83391 23.6215 8.83391C23.9046 8.83391 24.1349 8.60082 24.1349 8.31426C24.1349 8.30143 24.1328 8.2886 24.1307 8.27577C24.1307 8.27577 24.1328 8.27577 24.1349 8.27577C24.418 8.27577 24.6483 8.04268 24.6483 7.75612C24.6483 7.46957 24.418 7.23648 24.1349 7.23648V5.71603H24.6483C24.6483 6.00258 24.8785 6.23567 25.1616 6.23567C25.4447 6.23567 25.675 6.00258 25.675 5.71603C25.9581 5.71603 26.1884 5.48293 26.1884 5.19638Z'
			fill='#1A1A1A'
		/>
		<path
			d='M34.9196 4.67673C34.9196 4.39018 34.6894 4.15709 34.4063 4.15709C34.1232 4.15709 33.8929 4.39018 33.8929 4.67673H33.3795V4.15709C33.6626 4.15709 33.8929 3.92399 33.8929 3.63744C33.8929 3.35088 33.6626 3.11779 33.3795 3.11779C33.3795 2.83124 33.1493 2.59814 32.8662 2.59814C32.5831 2.59814 32.3528 2.83124 32.3528 3.11779C32.0697 3.11779 31.8394 3.35088 31.8394 3.63744C31.8394 3.92399 32.0697 4.15709 32.3528 4.15709V4.67673H31.8394C31.8394 4.39018 31.6091 4.15709 31.3261 4.15709C31.043 4.15709 30.8127 4.39018 30.8127 4.67673C30.5296 4.67673 30.2993 4.90983 30.2993 5.19638C30.2993 5.48293 30.5296 5.71603 30.8127 5.71603C30.8127 6.00258 31.043 6.23567 31.3261 6.23567C31.6091 6.23567 31.8394 6.00258 31.8394 5.71603H32.3528V7.23648C32.0697 7.23648 31.8394 7.46957 31.8394 7.75612C31.8394 8.04268 32.0697 8.27577 32.3528 8.27577C32.3528 8.27577 32.3549 8.27577 32.357 8.27577C32.357 8.2886 32.3528 8.30143 32.3528 8.31426C32.3528 8.60082 32.5831 8.83391 32.8662 8.83391C33.1493 8.83391 33.3795 8.60082 33.3795 8.31426C33.3795 8.30143 33.3774 8.2886 33.3753 8.27577C33.3753 8.27577 33.3774 8.27577 33.3795 8.27577C33.6626 8.27577 33.8929 8.04268 33.8929 7.75612C33.8929 7.46957 33.6626 7.23648 33.3795 7.23648V5.71603H33.8929C33.8929 6.00258 34.1232 6.23567 34.4063 6.23567C34.6894 6.23567 34.9196 6.00258 34.9196 5.71603C35.2027 5.71603 35.433 5.48293 35.433 5.19638C35.433 4.90983 35.2027 4.67673 34.9196 4.67673Z'
			fill='#1A1A1A'
		/>
		<path
			d='M30.2971 9.87546C30.2971 9.58891 30.0668 9.35582 29.7837 9.35582C29.5006 9.35582 29.2703 9.58891 29.2703 9.87546H28.757V9.35582C29.0401 9.35582 29.2703 9.12272 29.2703 8.83617C29.2703 8.54961 29.0401 8.31652 28.757 8.31652C28.757 8.02997 28.5267 7.79688 28.2436 7.79688C27.9605 7.79688 27.7302 8.02997 27.7302 8.31652C27.4471 8.31652 27.2169 8.54961 27.2169 8.83617C27.2169 9.12272 27.4471 9.35582 27.7302 9.35582V9.87546H27.2169C27.2169 9.58891 26.9866 9.35582 26.7035 9.35582C26.4204 9.35582 26.1901 9.58891 26.1901 9.87546C25.907 9.87546 25.6768 10.1086 25.6768 10.3951C25.6768 10.6817 25.907 10.9148 26.1901 10.9148C26.1901 11.2013 26.4204 11.4344 26.7035 11.4344C26.9866 11.4344 27.2169 11.2013 27.2169 10.9148H27.7302V12.4352C27.4471 12.4352 27.2169 12.6683 27.2169 12.9549C27.2169 13.2414 27.4471 13.4745 27.7302 13.4745C27.7302 13.4745 27.7323 13.4745 27.7345 13.4745C27.7345 13.4873 27.7302 13.5002 27.7302 13.513C27.7302 13.7995 27.9605 14.0326 28.2436 14.0326C28.5267 14.0326 28.757 13.7995 28.757 13.513C28.757 13.5002 28.7549 13.4873 28.7527 13.4745C28.7527 13.4745 28.7549 13.4745 28.757 13.4745C29.0401 13.4745 29.2703 13.2414 29.2703 12.9549C29.2703 12.6683 29.0401 12.4352 28.757 12.4352V10.9148H29.2703C29.2703 11.2013 29.5006 11.4344 29.7837 11.4344C30.0668 11.4344 30.2971 11.2013 30.2971 10.9148C30.5802 10.9148 30.8104 10.6817 30.8104 10.3951C30.8104 10.1086 30.5802 9.87546 30.2971 9.87546Z'
			fill='#1A1A1A'
		/>
		<path
			d='M11.2963 23.3906C11.2963 23.104 11.0661 22.871 10.783 22.871C10.4999 22.871 10.2696 23.104 10.2696 23.3906H9.75624V22.871C10.0393 22.871 10.2696 22.6379 10.2696 22.3513C10.2696 22.0647 10.0393 21.8317 9.75624 21.8317C9.75624 21.5451 9.52596 21.312 9.24287 21.312C8.95978 21.312 8.7295 21.5451 8.7295 21.8317C8.44641 21.8317 8.21613 22.0647 8.21613 22.3513C8.21613 22.6379 8.44641 22.871 8.7295 22.871V23.3906H8.21613C8.21613 23.104 7.98586 22.871 7.70276 22.871C7.41967 22.871 7.1894 23.104 7.1894 23.3906C6.9063 23.3906 6.67603 23.6237 6.67603 23.9102C6.67603 24.1968 6.9063 24.4299 7.1894 24.4299C7.1894 24.7164 7.41967 24.9495 7.70276 24.9495C7.98586 24.9495 8.21613 24.7164 8.21613 24.4299H8.7295V25.9503C8.44641 25.9503 8.21613 26.1834 8.21613 26.47C8.21613 26.7565 8.44641 26.9896 8.7295 26.9896C8.7295 26.9896 8.73161 26.9896 8.73373 26.9896C8.73373 27.0025 8.7295 27.0153 8.7295 27.0281C8.7295 27.3147 8.95978 27.5478 9.24287 27.5478C9.52596 27.5478 9.75624 27.3147 9.75624 27.0281C9.75624 27.0153 9.75413 27.0025 9.75201 26.9896C9.75201 26.9896 9.75413 26.9896 9.75624 26.9896C10.0393 26.9896 10.2696 26.7565 10.2696 26.47C10.2696 26.1834 10.0393 25.9503 9.75624 25.9503V24.4299H10.2696C10.2696 24.7164 10.4999 24.9495 10.783 24.9495C11.0661 24.9495 11.2963 24.7164 11.2963 24.4299C11.5794 24.4299 11.8097 24.1968 11.8097 23.9102C11.8097 23.6237 11.5794 23.3906 11.2963 23.3906Z'
			fill='#1A1A1A'
		/>
		<path
			d='M30.8107 23.3906C30.8107 23.104 30.5805 22.871 30.2974 22.871C30.0143 22.871 29.784 23.104 29.784 23.3906H29.2706V22.871C29.5537 22.871 29.784 22.6379 29.784 22.3513C29.784 22.0647 29.5537 21.8317 29.2706 21.8317C29.2706 21.5451 29.0404 21.312 28.7573 21.312C28.4742 21.312 28.2439 21.5451 28.2439 21.8317C27.9608 21.8317 27.7305 22.0647 27.7305 22.3513C27.7305 22.6379 27.9608 22.871 28.2439 22.871V23.3906H27.7305C27.7305 23.104 27.5003 22.871 27.2172 22.871C26.9341 22.871 26.7038 23.104 26.7038 23.3906C26.4207 23.3906 26.1904 23.6237 26.1904 23.9102C26.1904 24.1968 26.4207 24.4299 26.7038 24.4299C26.7038 24.7164 26.9341 24.9495 27.2172 24.9495C27.5003 24.9495 27.7305 24.7164 27.7305 24.4299H28.2439V25.9503C27.9608 25.9503 27.7305 26.1834 27.7305 26.47C27.7305 26.7565 27.9608 26.9896 28.2439 26.9896C28.2439 26.9896 28.246 26.9896 28.2481 26.9896C28.2481 27.0025 28.2439 27.0153 28.2439 27.0281C28.2439 27.3147 28.4742 27.5478 28.7573 27.5478C29.0404 27.5478 29.2706 27.3147 29.2706 27.0281C29.2706 27.0153 29.2685 27.0025 29.2664 26.9896C29.2664 26.9896 29.2685 26.9896 29.2706 26.9896C29.5537 26.9896 29.784 26.7565 29.784 26.47C29.784 26.1834 29.5537 25.9503 29.2706 25.9503V24.4299H29.784C29.784 24.7164 30.0143 24.9495 30.2974 24.9495C30.5805 24.9495 30.8107 24.7164 30.8107 24.4299C31.0938 24.4299 31.3241 24.1968 31.3241 23.9102C31.3241 23.6237 31.0938 23.3906 30.8107 23.3906Z'
			fill='#1A1A1A'
		/>
	</svg>
);

export default LogoBlack;
