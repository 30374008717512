/** @format */

export const linkResolver = (link: string) => {
	try {
		if (!process.env.NEXT_PUBLIC_WORDPRESS_API_URL || !isUrlAbsolute(link)) {
			return link;
		}

		const {pathname, host} = new URL(link);

		// Get subdomain:
		const subdomain = getSubdomain(host);

		// Resolve url for the current subdomain
		let DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;
		let DOMAIN_APP_URL = process.env.NEXT_PUBLIC_APP_URL;

		switch (subdomain) {
			case 'college':
				DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL_COLLEGE;
				DOMAIN_APP_URL = process.env.NEXT_PUBLIC_APP_URL_COLLEGE;
				break;
			case 'garden':
				DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL_GARDEN;
				DOMAIN_APP_URL = process.env.NEXT_PUBLIC_APP_URL_GARDEN;
				break;
			case 'tickets':
				DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL_TICKETS;
				DOMAIN_APP_URL = process.env.NEXT_PUBLIC_APP_URL_TICKETS;
				break;
			case 'thegregory':
				DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL_PUB;
				DOMAIN_APP_URL = process.env.NEXT_PUBLIC_APP_URL_PUB;
				break;
			default:
				DOMAIN_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;
				DOMAIN_APP_URL = process.env.NEXT_PUBLIC_APP_URL;
				break;
		}

		// if external
		const {host: host2} = new URL(DOMAIN_API_URL);

		// Handle file downloads such as pdfs which need to point to the cms:
		if (link.includes('/wp-content/uploads/') && link.includes('.pdf')) {
			const normalizedApiUrl = DOMAIN_API_URL.replace('/graphql', '');
			const downloadUrl = link.replace(DOMAIN_API_URL, normalizedApiUrl);

			return downloadUrl;
		}

		if (host !== host2) return link;

		return DOMAIN_APP_URL + pathname;
	} catch (e) {
		console.error(
			'WARNING - LinkResolver - Failed: ',
			link,
			' - Error: ',
			JSON.stringify(e),
		);
		return link;
	}
};

const isUrlAbsolute = url => url.indexOf('://') > 0 || url.indexOf('//') === 0;

export const removeHttpProtocol = (url: string) => {
	if (url.startsWith('http://')) {
		url = url.substr(7);
	}

	if (url.startsWith('https://')) {
		url = url.substr(8);
	}

	return url;
};

const getSubdomain = (host: string) => {
	const urlArray = host.split('.');
	if (
		urlArray[0] === 'college' ||
		urlArray[0] === 'garden' ||
		urlArray[0] === 'tickets' ||
		urlArray[0] === 'thegregory'
	) {
		return urlArray[0];
	}
	return '';
};
